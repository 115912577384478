

export const navLinks = [
  {
    id: "home",
    title: "Home",
  },
  {
    id: "Payment",
    title: "Payment",
  },
  {
    id: "Payroll ",
    title: "Payroll",
  },
  {
    id: "Projects",
    title: "Project",
  },
  {
    id: "P.E.O",
    title: "P.E.O",
  },
 
];




